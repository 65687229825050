import React, { ReactNode } from 'react';
import { Element } from './FieldGroup.styles';

export interface FieldGroupProps {
  className?: string;
  children?: ReactNode;
}

const FieldGroup = ({ children, ...props }: FieldGroupProps) => {
  return <Element {...props}>{children}</Element>;
};

export default FieldGroup;
