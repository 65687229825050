import * as z from 'zod';
import { VALIDATION_MESSAGES } from '@/features/forms/validation';

const validationLoginScheme = z.object({
  login: z.string().email({ message: VALIDATION_MESSAGES.email }),
  password: z.string().min(8, { message: VALIDATION_MESSAGES.required }),
  remember: z.boolean(),
});

export type FormLoginDataType = z.infer<typeof validationLoginScheme>;
export default validationLoginScheme;
