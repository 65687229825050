import React, { ReactNode } from 'react';
import { Element } from '@/components/grid/Grid.styles';

export interface GridProps {
  as?: 'div' | 'aside' | 'main' | 'article' | 'section';
  container?: boolean;
  item?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
  'col-sm'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
  'col-md'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
  'col-lg'?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
  className?: string;
  children?: ReactNode;
}

const Grid = ({ children, ...props }: GridProps) => {
  return <Element {...props}>{children}</Element>;
};

export default Grid;
