import styled, { css } from 'styled-components';
import { FieldsetProps } from '@/components/form/Fieldset/Fieldset';

export const FieldsetElement = styled.fieldset<FieldsetProps>`
  ${({ theme, size, align, flex, ...props }) => {
    return css`
      border: 1px solid ${theme.color.neutral_200};
      border-radius: ${theme.radius.xs};
      margin-bottom: ${theme.spacing.base};

      ${(size &&
        !['xs', 'sm', 'base'].includes(size) &&
        css`
          padding: ${theme.spacing.base};
          @media screen and (min-width: ${theme.breakpoint.sm}) {
            padding: ${theme.spacing.md};
          }
        `) ||
      css`
        padding: ${theme.spacing.base};
      `};

      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      legend {
        padding: 0 0.25rem;
        margin-left: -0.2rem;
        font-family: ${theme.typography.font_family.text};
        // SIZE
        font-size: ${size
          ? css`
              ${theme.typography.font_size[`text_${size}`]};
            `
          : css`
              ${theme.typography.font_size.text_md};
            `};
        font-weight: ${theme.typography.font_weight.text_bold};

        // Alignment
        ${align &&
        css`
          text-align: ${align};
        `};

        span {
          color: ${theme.color.neutral_500};
          font-size: ${theme.typography.font_size.text_xs};
          vertical-align: top;
        }
      }
    `;
  }};
`;
