import React, { ReactNode } from 'react';
import { Element } from '@/components/elevation/WhitePaper/WhitePaper.styles';
import { GridProps } from '@/components/grid/Grid';
import { ElevationProps } from '@/components/elevation/Elevation';
import { VisibilityBreakpointType } from '@/styles/utilities';

export interface WhitePaperProps extends GridProps, ElevationProps, VisibilityBreakpointType {
  /** The number will be the top position for the sticky*/
  sticky?: number;
  zIndex?: number;
  className?: string;
  /** The element should be visible from a given breakpoint and above only*/
  // visibility?: keyof DefaultTheme['breakpoint'];
  children?: ReactNode;
}

const WhitePaper = ({ children, ...props }: WhitePaperProps) => {
  return <Element {...props}>{children}</Element>;
};

export default WhitePaper;
