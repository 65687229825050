import React from 'react';
import { ParseFetchResponseType } from '@/features/forms/components/NotificationHandler/parseFetchResponse';
import Banner, { BannerProps } from '@/components/notification/Banner/Banner';

type BannerPropsExtendedType = Pick<
  BannerProps,
  'appearance' | 'showIcon' | 'showClose' | 'size' | 'closeTimeout' | 'show'
>;

export type NotficationHandlerProps = {
  feedback: ParseFetchResponseType | null;
  bannerProps?: BannerPropsExtendedType;
};

const NotificationHandler = ({ feedback, bannerProps, ...props }: NotficationHandlerProps) => {
  return (
    <>
      <Banner show={!!feedback} appearance={feedback?.appearance} {...(bannerProps as any)}>
        {feedback?.title && <strong>{feedback?.title}</strong>}
        <ul>
          {(feedback?.messages?.length &&
            feedback?.messages.map((msg) => (
              <li key={msg}>
                <small>{msg}</small>
              </li>
            ))) ||
            null}
        </ul>
      </Banner>
    </>
  );
};

export default NotificationHandler;
