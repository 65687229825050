import styled, { css } from 'styled-components';
import { GridProps } from './Grid';
import { Element as WhitePapperElement } from '@/components/elevation/WhitePaper/WhitePaper.styles';

export const gridColumnItem = css<GridProps>`
  ${({ theme, col, ...props }) => {
    return css`
      // Mobile
      ${col &&
      css`
        grid-column: auto/span ${col};
      `};

      // Small
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        ${props['col-sm'] &&
        css`
          grid-column: auto/span ${props['col-sm']};
        `};
      }

      // Tablet
      @media screen and (min-width: ${theme.breakpoint.md}) {
        ${props['col-md'] &&
        css`
          grid-column: auto/span ${props['col-md']};
        `};
      }

      // Desktop Large
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        ${props['col-lg'] &&
        css`
          grid-column: auto/span ${props['col-lg']};
        `};
      }
    `;
  }};
`;

export const Element = styled.div.withConfig({
  shouldForwardProp: (prop) => !['container', 'item', 'col', 'col-sm', 'col-md', 'col-lg'].includes(prop),
})<GridProps>`
  ${({ theme, container, item, col, ...props }) => {
    return css`
      // Container
      ${container &&
      css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: ${theme.spacing.base};

        padding: ${theme.spacing.base};
        @media screen and (min-width: ${theme.breakpoint.sm}) {
          padding: ${theme.spacing.base} 0;
        }
      `};

      // Cols
      ${gridColumnItem};

      // Item
      ${item &&
      css`
        // Add margins when we have WhitePaper nested within GridItem
        ${WhitePapperElement}:not(:last-child) {
          margin-bottom: 1rem;
        }
      `};
    `;
  }};
`;
