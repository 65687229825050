import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      margin-bottom: 1rem;
    `;
  }};
`;

export const FormElement = styled.form`
  ${({ theme, ...props }) => {
    return css`
      max-width: 56rem;
      margin: 0 auto;
    `;
  }};
`;

export const FormContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      > div {
        margin-bottom: 1rem;
      }
    `;
  }};
`;
