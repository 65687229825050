export type ResponseDataType =
  | {
  message?: string | null;
  errors?: { [key: string]: [string] | string };
  success?: string;
}
  | string;

function buildListOfItems(response: ResponseDataType) {
  let list: (string | undefined)[] = [];
  if (!response || typeof response === 'string') {
    list.push(response);
    return list;
  }

  if (typeof response === 'object' && response?.success) {
    list.push(response.success);
    return list;
  }

  if (typeof response === 'object' && response?.errors) {
    Object.keys(response.errors).forEach((key) => {
      let item = response?.errors?.[key];
      if (item && typeof item === 'string') {
        list.push(item);
      }

      if (Array.isArray(item)) {
        item.forEach((error) => {
          if (Array.isArray(error)) {
            error.map((item) => {
              list.push(item);
            });
          } else {
            list.push(error);
          }
        });
      }
    });
  }
  return list;
}

export type ParseFetchResponseType = {
  status: number;
  title: string;
  appearance: 'success' | 'error' | unknown;
  messages: (string | undefined)[];
} | null;

export default async function parseFetchResponse(response: Response): Promise<ParseFetchResponseType> {
  let title;
  let appearance;
  let messages: (string | undefined)[] = [];

  const status = response.status;
  const data = await response.json();

  if (typeof data?.message === 'string') {
    title = data.message;
  } else if (typeof data === 'string') {
    title = data;
  }

  // success
  if (typeof data?.success === 'string') {
    appearance = 'success';
    messages = buildListOfItems(data);
  }

  if (typeof data?.errors === 'object') {
    appearance = 'error';
    messages = buildListOfItems(data);
  }

  if (!appearance) {
    if (status < 400) {
      appearance = 'success';
    } else {
      appearance = 'error';
    }
  }

  return {
    status,
    appearance,
    title,
    messages
  };
}
