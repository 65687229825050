import styled, { css } from 'styled-components';
import { WhitePaperProps } from './WhitePaper';
import { Element as Elevation } from '../Elevation.styles';
import { gridColumnItem } from '@/components/grid/Grid.styles';
import { visibilityBreakpoint } from '@/styles/utilities';

export const Element = styled(Elevation)
  .attrs({ shadow: 'base', radius: 'sm', spacing: 'base' })
  .withConfig({
    shouldForwardProp: (prop) =>
      !['container', 'item', 'col', 'col-sm', 'col-md', 'col-lg', 'sticky', 'zIndex', 'visibility'].includes(prop),
  })<WhitePaperProps>`
  ${({ theme, col, sticky, zIndex, visibility, ...props }) => {
    return css`
      // Integrate WhitePaper with Grid Container Wrapper component
      ${gridColumnItem};

      ${visibilityBreakpoint};

      // Adds default 32 pixels spacing for tablet and above
      // TODO: REVIEW THIS SPACING IS NOT CONFLICTING
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        padding: ${theme.spacing.md};
      }

      ${sticky &&
      css`
        //position: fixed;
        //bottom: ${sticky}rem;

        // Only on desktop for now
        @media screen and (min-width: ${theme.breakpoint.sm}) {
          position: sticky;
          top: ${sticky}rem;

          ${zIndex !== undefined &&
          css`
            z-index: ${zIndex};
          `};
        }
      `}
    `;
  }};
`;
