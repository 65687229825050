import React, { MouseEvent, ReactNode } from 'react';
import { Element } from './HeaderLogo.styles';
import LogoIcon from '@/components/logo/LogoIcon';
import Link from '@/providers/Link';

export interface HeaderLogoProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const HeaderLogo = ({ children, ...props }: HeaderLogoProps) => {
  return (
    <Element>
      <Link href="/">
        <LogoIcon />
      </Link>
    </Element>
  );
};

export default HeaderLogo;
