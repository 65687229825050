import styled, { css } from 'styled-components';
import { HeaderLogoProps } from './HeaderLogo';

export const Element = styled.div<HeaderLogoProps>`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    `;
  }};
`;
