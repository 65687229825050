import React, { useState } from 'react';
import { Element, FormContainer, FormElement } from './FormLogin.styles';
import HeaderLogo from '@/components/header/HeaderLogo/HeaderLogo';
import Grid from '@/components/grid/Grid';
import { Container } from '@/components/container';
import TitleFeatured from '@/components/typography/TitleFeatured/TitleFeatured';
import Label from '@/components/form/Label/Label';
import TextField from '@/components/form/TextField/TextField';
import FieldGroup from '@/components/form/FieldGroup/FieldGroup';
import { useForm } from 'react-hook-form';
import validationLoginScheme, { FormLoginDataType } from '@/features/account/login/validationScheme';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/button';
import Text from '@/components/typography/Text/Text';
import { ACCOUNT_LINK, fetchClient, FORMS_ENDPOINT } from '@/helpers/browser';
import Checkbox from '@/components/form/Checkbox/Checkbox';
import Fieldset from '@/components/form/Fieldset/Fieldset';
import Link from '@/providers/Link';
import WhitePaper from '@/components/elevation/WhitePaper/WhitePaper';
import parseFetchResponse, {
  ParseFetchResponseType,
} from '@/features/forms/components/NotificationHandler/parseFetchResponse';
import NotificationHandler from '@/features/forms/components/NotificationHandler/NotificationHandler';
import { useRouter } from 'next/router';

export interface FormLoginProps {
  data: {
    csrf_token?: string;
    form_signed_authenticity?: string;
  };
}

const FormLogin = ({ data: { form_signed_authenticity, csrf_token }, ...props }: FormLoginProps) => {
  const router = useRouter();
  const [feedback, setFeedback] = useState<ParseFetchResponseType>(null);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormLoginDataType>({
    resolver: zodResolver(validationLoginScheme),
    defaultValues: {
      login: '',
      password: '',
      remember: false,
    },
  });

  const onSubmit = async (data: FormLoginDataType) => {
    console.log(`____onSubmit____`);
    if (!isValid) {
      return;
    }

    const payload = { ...data };

    try {
      const response = await fetchClient(FORMS_ENDPOINT.form_login, {
        method: 'POST',
        body: JSON.stringify(payload),
        // mode: 'same-origin',
      });

      if (response.ok) {
        await router.push('/');
      } else {
        setFeedback(await parseFetchResponse(response));
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <HeaderLogo />
      <Container size="sm">
        <Grid container>
          <Grid item col={12}>
            <WhitePaper>
              <Element>
                <TitleFeatured
                  title="Área do Agente"
                  as="h1"
                  // description="Espaço destinado à agente de viagens apenas."
                  size="base"
                />
              </Element>

              <FormElement
                action="/proxy/action/form-login"
                method="post"
                id="frmLogin"
                name="frmLogin"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormContainer>
                  <NotificationHandler feedback={feedback} />
                  <Fieldset align="center">
                    <legend>Acesse sua conta</legend>
                    <FieldGroup>
                      <Label htmlFor="email" required>
                        Email
                      </Label>
                      <TextField type="email" id="email" error={errors.login?.message} {...register('login')} />
                    </FieldGroup>

                    <FieldGroup>
                      <Label htmlFor="password" required>
                        Senha
                      </Label>
                      <TextField
                        type="password"
                        id="password"
                        error={errors.password?.message}
                        {...register('password')}
                      />
                    </FieldGroup>

                    <FieldGroup>
                      <Checkbox
                        label="Mantenha me logado"
                        id="remember"
                        error={errors.remember?.message}
                        {...register('remember')}
                      />
                    </FieldGroup>

                    <FieldGroup>
                      <Button type="submit" appearance="primary" disabled={isSubmitting} block>
                        LOGIN
                      </Button>
                    </FieldGroup>
                  </Fieldset>

                  <FieldGroup>
                    <Text size="sm" as="p" margin="sm">
                      <a href={ACCOUNT_LINK.forgot_password}>Esqueci minha senha</a>
                    </Text>
                    <Text size="sm" as="p">
                      Ainda não tem conta?{' '}
                      <strong>
                        <Link href={ACCOUNT_LINK.signup}>Crie sua Conta</Link>
                      </strong>
                    </Text>
                  </FieldGroup>
                </FormContainer>
              </FormElement>
            </WhitePaper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FormLogin;
