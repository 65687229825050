import React, { ReactNode } from 'react';
import { FieldsetElement } from './Fieldset.styles';

export interface FieldsetProps {
  className?: string;
  readonly size?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl';
  readonly align?: 'center';
  readonly flex?: boolean;
  children?: ReactNode;
}

const Fieldset = React.forwardRef<HTMLLabelElement, FieldsetProps>(({ children, ...props }: FieldsetProps, ref) => {
  return <FieldsetElement {...props}>{children}</FieldsetElement>;
});

Fieldset.displayName = 'Fieldset';

export default Fieldset;
