import React from 'react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import HeadMetaTag from '@/components/metatags/HeadMetaTag';
import CustomError from '@/features/errors';
import FormLogin from '@/features/account/login/FormLogin';

export const getServerSideProps: GetServerSideProps = async ({ req, query }) => {
  // TODO:
  //  - Review if the request to this endpoint is really required.
  //    ==> it was commented ou before release because is not in use
  // const path = `/api/form-signed-authenticity`;
  // const [pageProps] = await Promise.all([fetchDataHandler(path, 'FORM LOGIN', req)]);
  //
  // const { props, notFound, redirect } = pageProps;
  // if (notFound !== undefined) return { notFound: true };
  // if (redirect !== undefined) return { redirect };
  // if (props !== undefined) {
  //   let out = {
  //     ...props,
  //   };
  //
  //   return { props: out };
  // }
  return {
    props: {
      data: {},
    },
  };
};

const Page = ({ error, data, ...props }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  if (error) {
    return <CustomError error={error} />;
  }

  const meta: Metadata = {
    title: 'Login | Kangaroo Tours',
    robots: 0,
    description: '',
  };

  return (
    <>
      {/*<pre>{JSON.stringify({ data }, null, 2)}</pre>*/}
      <HeadMetaTag meta={meta} />
      <FormLogin data={data} />
    </>
  );
};

export default Page;
