import styled, { css } from 'styled-components';
import { FieldGroupProps } from './FieldGroup';

export const Element = styled.div<FieldGroupProps>`
  ${({ theme, ...props }) => {
    return css`
      margin-bottom: ${theme.spacing.sm};
      &:last-of-type {
        margin-bottom: 0;
      }
    `;
  }};
`;
